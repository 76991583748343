<template>
  <b-overlay :show="loading">
    <b-card-actions action-collapse title="Daftar Setting Marketing">
      <!-- ref="refreshCard" -->
      <!-- action-refresh
      @refresh="refreshStop('refreshCard')" -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-tambah
            variant="primary"
            v-if="allowCreate()"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Tambah
          </b-button>
          <b-modal
            v-if="allowCreate() || allowUpdate()"
            v-model="showModal"
            id="modal-tambah"
            cancel-variant="secondary"
            ok-title="Tambah"
            cancel-title="Batal"
            centered
            title="Form Tambah"
          >
            <b-form>
              <!-- <b-form-group>
                <label for="nama_gudang">Nama</label>
                <b-form-input v-model="form.nama_gudang" id="gudang" type="gudang" placeholder="Nama gudang" />
              </b-form-group> -->
              <b-form-group>
                <label for="teritory">Teritori</label>
                <b-form-select
                  v-model="form.teritory_id"
                  :options="teritory_id"
                  label="Teritori"
                />
              </b-form-group>
              <b-form-group>
                <label for="rute">Rute</label>
                <v-select
                  v-model="selectedRutes"
                  :options="rutes"
                  multiple
                  label="text"
                />
              </b-form-group>
              <b-form-group>
                <label for="kendaraan">Kendaraan</label>
                <b-form-select
                  v-model="form.kendaraan_id"
                  :options="kendaraan_id"
                  label="Kendaraan"
                />
              </b-form-group>
              <b-form-group>
                <label class="block" for="sales">Sales</label>
                <b-form-select
                v-model="form.karyawan_id"
                :options="karyawan_id"
                label="Sales"
                />
                <strong v-if="isAllSalesOut" class="block text-danger"><i>Semua sales sudah ditempatkan</i></strong>
              </b-form-group>
              <b-form-group>
                <label for="driver">Driver</label>
                <b-form-select
                  v-model="form.driver_id"
                  :options="driver_id"
                  label="Driver"
                />
              </b-form-group>
              <b-form-group>
                <label for="alamat">Alamat</label>
                <b-form-textarea
                  v-model="form.alamat"
                  id="alamat"
                  placeholder="Isi Alamat"
                />
              </b-form-group>
              <b-form-group>
                <label for="keterangan">Keterangan</label>
                <b-form-textarea
                  v-model="form.keterangan"
                  id="keterangan"
                  placeholder="Isi Keterangan"
                />
              </b-form-group>
            </b-form>
  
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="success" @click="submit">
                  Simpan
                </b-button>
                <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                  Reset
                </b-button>
                <b-button size="sm" variant="danger" @click="showModal = false">
                  Keluar
                </b-button>
              </section>
            </template>
          </b-modal>
          <b-modal
            v-if="allowUpdate()"
            v-model="showModalEdit"
            id="modal-ubah"
            cancel-variant="secondary"
            ok-title="Ubah"
            cancel-title="Batal"
            centered
            title="Form Ubah"
          >
            <b-form>
              <!-- <b-form-group>
                <label for="nama_gudang">Nama Gudang</label>
                <b-form-input v-model="form.nama_gudang" id="gudang" type="gudang" placeholder="Nama gudang" />
              </b-form-group> -->
              <b-form-group>
                <label for="teritory">Teritori</label>
                <b-form-select
                  v-model="form.teritory_id"
                  :options="teritory_id"
                  label="Teritori"
                />
              </b-form-group>
              <b-form-group>
                <label for="kendaraan">Kendaraan</label>
                <b-form-select
                  v-model="form.kendaraan_id"
                  :options="kendaraan_id"
                  label="Kendaraan"
                />
              </b-form-group>
              <b-form-group>
                <label for="sales">Sales</label>
                <b-form-select
                  v-model="form.karyawan_id"
                  :options="karyawan_id"
                  label="Sales"
                />
              </b-form-group>
              <b-form-group>
                <label for="driver">Driver</label>
                <b-form-select
                  v-model="form.driver_id"
                  :options="driver_id"
                  label="Driver"
                />
              </b-form-group>
              <b-form-group>
                <label for="alamat">Alamat</label>
                <b-form-textarea
                  v-model="form.alamat"
                  id="alamat"
                  placeholder="Isi Alamat"
                />
              </b-form-group>
              <b-form-group>
                <label for="keterangan">Keterangan</label>
                <b-form-textarea
                  v-model="form.keterangan"
                  id="keterangan"
                  placeholder="Isi Keterangan"
                />
              </b-form-group>
            </b-form>
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="info" @click="submit">
                  Ubah
                </b-button>
                <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                  Hapus isian
                </b-button>
                <b-button size="sm" variant="danger" @click="showModalEdit = false">
                  Keluar
                </b-button>
              </section>
            </template>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
  
            <template #cell(teritory_id)="data">
              <b-badge variant="primary">
                {{ getSalesname(data.item) }}
              </b-badge>
            </template>
            <template #cell(driver_id)="data">
              <b-badge variant="primary">
                {{ getDriverName(data.item) }}
              </b-badge>
            </template>
  
            <template #cell(actions)="row">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                right
                size="sm"
                variant="primary"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <feather-icon icon="ListIcon" />
                </template>
                <b-dropdown-item-button
                  ><b-button
                    v-if="allowUpdate()"
                    v-b-tooltip.hover.right="'Ubah'"
                    size="sm"
                    @click="edit(row.item)"
                    class="mr-1"
                    variant="outline-info"
                  >
                    <feather-icon icon="EditIcon" /> </b-button
                  ><b-button
                    v-if="allowDelete()"
                    v-b-tooltip.hover.right="'Hapus'"
                    size="sm"
                    @click="remove(row.item)"
                    class=""
                    variant="outline-danger"
                  >
                    <feather-icon icon="TrashIcon" /> </b-button
                ></b-dropdown-item-button>
              </b-dropdown>
            </template>
  
            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>
        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
          <pre>{{ infoModal.content }}</pre>
        </b-modal>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BOverlay,
  BDropdownItemButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BCardActions,
    BDropdownItemButton,
    ToastificationContent,
    VBTooltip,
    BModal,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      isAllSalesOut: false,
      showModalEdit: false,
      showModal: false,
      id: null,
      myRutes: [],
      selectedRutes: [],
      form: {
        nama_gudang: "-",
        teritory_id: 0,
        jenis: 2,
        kendaraan_id: null,
        karyawan_id: null,
        driver_id: null,
        keterangan: "",
        rutes: null
      },
      karyawan_id: null,
      teritory_id: [],
      sales: [],
      driver_id: [],
      kendaraan_id: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // { key: "id", label: "ID"},
        // { key: "nama_gudang", label: "Nama Gudang", sortable: true },
        { key: "teritori.nama_teritori", label: "Teritori", sortable: true },
        { key: "kendaraan.nama_kendaraan", label: "Kendaraan" },
        { key: "karyawan.nama_lengkap", label: "Sales" },
        { key: "driver.nama_lengkap", label: "Driver" },
        { key: "alamat", label: "alamat" },
        { key: "keterangan", label: "Keterangan" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      rutes: [],
      loading: false
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // Set the initial number of items
    await this.getData();
    this.getRutes()
    this.getDataDriver();
    this.getDataSales();
    this.getDataTeritori();
    this.getDataKendaraan();
  },
  methods: {
    async getRutes() {
      const rutes = await this.$store.dispatch('rute/getData', {order: 'desc'})
      this.rutes = rutes.map(item => ({value: item.id, text: item.nama_rute}))
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModal = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data gudang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("gudang/save", [item])
            .then(async () => {
              await this.getData();
              this.getDataSales()
              this.pesanBerhasilHapus();
            })
            .catch((e) => {
              this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        teritori: 0,
        nama_gudang: "-",
        sub_menu: 0,
        create: null,
        update: null,
        delete: null,
      };
      this.selectedRutes = []
    },
    submit() {
      if (this.form.nama_gudang == null || this.form.nama_gudang == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if (this.id) {
        this.form.id = this.id;
      }
      const payload = this.form;
      this.loading = true
      this.$store
      .dispatch("gudang/save", [payload])
        .then(async () => {
          this.loading = false
          if(this.selectedRutes && this.selectedRutes.length > 0) {
            const myR = this.selectedRutes.map(rute_id => {
              const payload = {
                rute_id: rute_id.value,
                sales_id: this.form.karyawan_id
              }

              if(rute_id.id) {
                payload.id = rute_id.id
              }

              return payload
            })

            if(myR.length > 0) {
              await this.$store.dispatch('rute/saveMyData', myR)
            }

            // check if there delete rute
            const deletedRutes = []
            this.myRutes.map(rute => {
              const check = this.selectedRutes.find(item => item.value == rute.value)
              if(!check) {
                deletedRutes.push(rute)
              }
            })
            
            if(deletedRutes.length > 0) {
              deletedRutes.map(item => {
                item.fungsi = 2
                item.rute_id = item.value
                item.sales_id = this.form.karyawan_id
              })

              await this.$store.dispatch('rute/saveMyData', deletedRutes)
            }
          }
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          await this.getData();
          this.pesanBerhasilSimpan();
          this.resetForm();
          this.getDataSales()
          this.showModal = false;
          this.showModalEdit = false;
        })
        .catch((e) => {
          this.loading = false
          this.displayError(e)
          return false
        });
      // this.pesanGagalSimpan()
    },
    async edit(item) {
      this.selectedRutes = []
      let { kendaraan, teritori, karyawan, driver } = item;
      this.id = item.id;
      this.form = item;
      if (kendaraan) this.form.kendaraan_id = kendaraan.id;
      if (teritori) this.form.teritory_id = teritori.id;
      if (karyawan) this.form.karyawan_id = karyawan.id;
      if (driver) this.form.driver_id = driver.id;
      this.showModal = true;
      
      // get route sales
      const {karyawan_id} = item
      const rutes = await this.$store.dispatch('rute/getMyData', {sales_id: karyawan_id})
      if(rutes && rutes.length > 0) {
        const selectedRutes = rutes.map(item => ({
          id: item.id,
          value: item.rute_id,
          text: item.rute ? item.rute.nama_rute : null
        }))

        this.selectedRutes = selectedRutes
        this.myRutes = selectedRutes
      }
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("gudang/getData", { jenis: 2 })
        .then(() => {
          this.items = this.$store.state.gudang.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getDataTeritori() {
      this.$store
        .dispatch("teritori/getData", {})
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.teritori.datas));
          ter.map((item) => {
            item.value = item.id;
            item.text = item.nama_teritori + " - " + item.keterangan;
          });
          this.teritory_id = ter;
        })
        .catch((e) => console.error(e));
    },
    getDataKendaraan() {
      this.$store
        .dispatch("kendaraan/getData", {})
        .then(() => {
          let ken = JSON.parse(JSON.stringify(this.$store.state.kendaraan.datas));
          ken.map((item) => {
            item.value = item.id;
            item.text = item.nama_kendaraan + " - " + item.plat_nomer;
          });
          this.kendaraan_id = ken;
        })
        .catch((e) => console.error(e));
    },
    getDataSales() {
      this.$store.dispatch("karyawan/getData", { jabatan_id: 8 }).then(() => {
        let sales = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
        sales.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        
        // if(this.items.length > 0) {
        //   // get id sales yg ada di data gudang marketing (yg suadah terpakai)
        //   const mobils = this.items.filter(mobil => mobil.karyawan != null)
        //   const mobileSalesIds = mobils.map(mobil => mobil.karyawan.id)
        //   sales = sales.filter(sl => !mobileSalesIds.includes(sl.id))
        //   this.isAllSalesOut = sales.length < 1
        //   console.log('isAllSalesOut', this.isAllSalesOut)
        // }
        // console.log('itm', this.items)
        this.karyawan_id = sales;
      });
    },
    getSalesname(data) {
      let find = this.karyawan_id.find((item) => item.id == data.karyawan_id);
      return find ? find.nama_lengkap : "-";
    },
    getDataDriver() {
      this.$store.dispatch("karyawan/getData", { jabatan_id: 9 }).then(() => {
        let dri = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
        dri.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.driver_id = dri;
      });
    },
    getDriverName(data) {
      let find = this.driver_id.find((item) => item.id == data.driver_id);
      return find ? find.nama_lengkap : "-";
    },

    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
